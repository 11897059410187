import { Injectable } from '@angular/core';
import { APIURL } from '../global/constant';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor(private commonService: CommonService) {}

  getAllCountryList(){
    return this.commonService.getWithToken(APIURL.country);
  }

  getCountry(payload) {
    let param = this.commonService.getParamsFromObj(payload);
    return this.commonService.getWithTokenWithParams(APIURL.country, param);
  }

  addCountry(data) {
    return this.commonService.postWithToken(APIURL.country, data);
  }
  editCountry(data, id) {
    return this.commonService.postWithToken(
      `${APIURL.country}?countryId=${id}`,
      data
    );
  }
  deleteCountry(id) {
    return this.commonService.deleteWithToken(`${APIURL.country}/${id}`);
  }

  blockUnblockCountry(id, data) {
    return this.commonService.post(`${APIURL.country}/${id}`, data);
  }
}
