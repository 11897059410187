import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { CountryService } from './services/country.service';
import { SharedService } from './services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public sharedService: SharedService,
    private spinner: NgxSpinnerService
  ) {
    this.sharedService.isSpinnerShow.subscribe((isShow) => {
      if (isShow) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
  }
  ngOnInit() {}
}
