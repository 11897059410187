<nz-sider nzCollapsible nzWidth="200px">
  <div class="logo"></div>
  <ul nz-menu nzTheme="dark" nzMode="inline">
    <li nz-menu-item>
      <i nz-icon nzType="pie-chart"></i>
      <span>Option 1</span>
    </li>
    <li nz-menu-item>
      <i nz-icon nzType="desktop"></i>
      <span>Option 2</span>
    </li>
    <li nz-submenu nzTitle="User" nzIcon="user">
      <ul>
        <li nz-menu-item>Tom</li>
        <li nz-menu-item>Bill</li>
        <li nz-menu-item>Alex</li>
      </ul>
    </li>
    <li nz-submenu nzTitle="Team" nzIcon="team">
      <ul>
        <li nz-menu-item>Team 1</li>
        <li nz-menu-item>Team 2</li>
      </ul>
    </li>
    <li nz-menu-item>
      <i nz-icon nzType="file"></i>
      <span>File</span>
    </li>
  </ul>
</nz-sider>
