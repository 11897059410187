import { Injectable } from '@angular/core';
import { APIURL } from '../global/constant';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class CityService {
  constructor(private commonService: CommonService) {}

  getAllCity(payload) {
    let param = this.commonService.getParamsFromObj(payload);
    return this.commonService.getWithTokenWithParams(APIURL.city, param);
  }

  getCity(stateId, payload) {
    let param = this.commonService.getParamsFromObj(payload);
    return this.commonService.getWithTokenWithParams(
      APIURL.city + '/' + stateId,
      param
    );
  }

  addCity(data) {
    return this.commonService.postWithToken(APIURL.city, data);
  }
  editCity(data, id) {
    return this.commonService.postWithToken(
      `${APIURL.city}?cityId=${id}`,
      data
    );
  }
  deleteCity(id) {
    return this.commonService.deleteWithToken(`${APIURL.city}/${id}`);
  }

  blockUnblockCity(id, data) {
    return this.commonService.post(`${APIURL.city}/${id}`, data);
  }
}
