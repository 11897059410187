export const APIURL = {
  // Admin Auth Login
  adminAuthLogin: '/auth/login',

  // Outlet API End-Point
  outlet: '/outlet',

  // restaurant user role type API End-Point
  restaurantUserRoleType: '/restaurantUserRoleType',

  // restaurant user role type API End-Point
  restaurantUserRole: '/restaurant-user-role',

  // restaurant user role type API End-Point
  restaurantUser: '/restaurant-user',

  //state city country apis endpoint

  country: '/country',
  state: '/state',
  city: '/city',

  // subscription-plan
  subscriptionPlan: '/subscription-plan',

  // restaurant permission module
  restaurantModulePermission: '/restaurant-permission-module',

  //restaurant api module
  restaurant: '/restaurant',
};

export const LOCAL_STORAGE_KEYS = {
  TOKEN: 'token',
};
