import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  currentPage: any = '';

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    public sharedService: SharedService
  ) {}

  ngOnInit() {
    this.router.events.subscribe(() => {
      this.route.url.subscribe(() => {
        this.currentPage = this.route.snapshot.firstChild?.data;
      });
    });
  }
}
