<nz-breadcrumb>
  <nz-breadcrumb-item *ngIf="route.snapshot?.data?.page">
    <span (click)="sharedService.gotoPage(route.snapshot?.data?.url)">
      <i nz-icon nzType="home" nzTheme="outline"></i>
      <span>
        {{ route.snapshot.data.page }}
      </span>
    </span>
  </nz-breadcrumb-item>
  <nz-breadcrumb-item *ngIf="currentPage.page">{{
    currentPage.page
  }}</nz-breadcrumb-item>
</nz-breadcrumb>
