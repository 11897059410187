import { Injectable } from '@angular/core';
import * as localforage from 'localforage';

@Injectable({
  providedIn: 'root',
})
export class IndexDbStorageService {
  constructor() {
    localforage.config({
      driver: [
        localforage.INDEXEDDB,
        localforage.WEBSQL,
        localforage.LOCALSTORAGE,
      ],
      storeName: 'digipos-admin',
      name: 'digipos-admin',
    });
  }

  storeItem(key: string, value: string) {
    localforage.setItem(
      key,
      typeof value === 'string' ? value : JSON.stringify(value)
    );
  }

  readStorage(key: string) {
    return localforage.getItem(key);
  }

  removeStorage(key: string) {
    localforage.removeItem(key);
  }

  clearStorage() {
    localforage.clear();
  }

  // store particular key details
  setDataInIndexedDB(key, value) {
    return localforage
      .setItem(key, JSON.stringify(value))
      .then(() => {})
      .catch(() => {});
  }

  // fetch particular key details
  async getDataFromIndexedDB(key) {
    return new Promise((resolve, reject) => {
      localforage
        .getItem(key)
        .then((result: any) => {
          resolve(JSON.parse(result));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // For Remove Particular Field/Key
  removeDataFromIndexedDB(key) {
    return new Promise((resolve, reject) => {
      localforage
        .removeItem(key)
        .then((result: any) => {
          const remove = 'Key Removed';
          return resolve(remove);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  // Database has been entirely deleted.
  clearDataFromIndexedDB() {
    return localforage.clear();
  }

  //#region Localstorage Service

  getDataFromLocalStorage(key) {
    return localStorage.getItem(key);
  }

  setDataInLocalStorage(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  removeDataFromLocalStorage(key) {
    localStorage.removeItem(key);
  }

  //#endregion
}
