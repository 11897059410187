import { Injectable } from '@angular/core';
import { APIURL } from '../global/constant';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  constructor(private commonService: CommonService) { }

  getStateList(){
    return this.commonService.getWithToken(APIURL.state);
  }

  getAllState(payload) {
    let param = this.commonService.getParamsFromObj(payload);
    return this.commonService.getWithTokenWithParams(APIURL.state, param);
  }

  getState(countryId, payload) {
    let param = this.commonService.getParamsFromObj(payload);
    return this.commonService.getWithTokenWithParams(
      APIURL.state + '/' + countryId,
      param
    );
  }

  addState(data) {
    return this.commonService.postWithToken(APIURL.state, data);
  }
  editState(data, id) {
    return this.commonService.postWithToken(
      `${APIURL.state}?stateId=${id}`,
      data
    );
  }
  deleteState(id) {
    return this.commonService.deleteWithToken(`${APIURL.state}/${id}`);
  }

  blockUnblockState(id, data) {
    return this.commonService.post(`${APIURL.state}/${id}`, data);
  }
}
