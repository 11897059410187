import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APIURL, LOCAL_STORAGE_KEYS } from '../global/constant';
import { CommonService } from './common.service';
import { IndexDbStorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private commonService: CommonService,
    private indexDbService: IndexDbStorageService,
    private router: Router
  ) {}

  login(data) {
    return this.commonService.post(APIURL.adminAuthLogin, data);
  }

  async loggedIn() {
    return await this.indexDbService
      .getDataFromIndexedDB('userDetails')
      .then((res: any) => {
        if (res) {
          return true;
        } else {
          return false;
        }
      });
  }

  async logout() {
    await this.indexDbService.removeDataFromIndexedDB(LOCAL_STORAGE_KEYS.TOKEN);
    await this.indexDbService.removeDataFromIndexedDB('userDetails');

    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
