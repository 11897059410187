import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { CityService } from './city.service';
import { CountryService } from './country.service';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  isSpinnerShow = new BehaviorSubject<boolean>(false);
  countryList = new BehaviorSubject<any>(undefined);
  cityList = new BehaviorSubject<any>(undefined);
  stateList = new BehaviorSubject<any>(undefined);

  constructor(
    private router: Router,
    private toastr: ToastrService,
    public modalService: NzModalService,
    public countryService: CountryService,
    public stateService: StateService,
    public cityService: CityService
  ) {}

  showSpinner() {
    this.isSpinnerShow.next(true);
  }

  hideSpinner() {
    this.isSpinnerShow.next(false);
  }

  gotoPage(url: string) {
    this.router.navigateByUrl(url);
  }

  //#region Toastr Functions

  successToast(message: any, title?: any) {
    return this.toastr.success(message, title);
  }

  errorToast(message: any, title?: any) {
    return this.toastr.error(message, title);
  }

  warningToast(message: any, title?: any) {
    return this.toastr.warning(message, title);
  }

  infoToast(message: any, title?: any) {
    return this.toastr.info(message, title);
  }
  //#endregion

  getFormControls(formGroupName: FormGroup) {
    return formGroupName.controls;
  }

  async getCountryList(params) {
    try {
      let res: any = await this.countryService.getCountry(params).toPromise();
      this.countryList.next(res);

      return res;
    } catch (error: any) {
      this.errorToast(error.error.message);
      return error;
    }
  }
  async getStateList(countryId, payload) {
    try {
      let res: any = await this.stateService
        .getState(countryId, payload)
        .toPromise();
      this.stateList.next(res);
      return res;
    } catch (error: any) {
      this.errorToast(error.error.message);
      return error;
    }
  }
  async getCityList(stateId, payload) {
    try {
      let res: any = await this.cityService
        .getCity(stateId, payload)
        .toPromise();
      this.cityList.next(res);
      return res;
    } catch (error: any) {
      this.errorToast(error.error.message);
      return error;
    }
  }

  convertMonthInWords(monthCount) {
    var months = { one: 'Month', other: 'Months' };
    var years = { one: 'Year', other: 'Years' };
    var m = monthCount % 12;
    var y = Math.floor(monthCount / 12),
      result: any = [];

    y && result.push(y + ' ' + this.getPlural(y, years));
    m && result.push(m + ' ' + this.getPlural(m, months));
    return result.join(' & ');
  }

  getPlural(number: any, word: any) {
    return (number === 1 && word.one) || word.other;
  }
}
