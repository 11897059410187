import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { NgZorroAntdModule } from './ng-zorro-module/ng-zorro-antd.module';
import { BreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
import { InputRestrictionDirective } from '../directives/InputRestrictionDirective.directive';
import { OnlynumericDirective } from '../directives/onlynumeric.directive';
import { FloatNumberDirective } from '../directives/floatNumber.directive';
import { NumberWithDirective } from '../directives/numberWithComma.directive';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    BreadcrumbComponent,
    InputRestrictionDirective,
    OnlynumericDirective,
    FloatNumberDirective,
    NumberWithDirective,
  ],
  imports: [CommonModule, NgZorroAntdModule],
  exports: [
    NgZorroAntdModule,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    BreadcrumbComponent,
    InputRestrictionDirective,
    OnlynumericDirective,
    FloatNumberDirective,
    NumberWithDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
